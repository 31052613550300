import { HttpBackend, HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { environment } from './environments/environment';
import { tokenInterceptor } from './app/interceptors/token.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { provideNoopAnimations } from '@angular/platform-browser/animations';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { WOLKIE_LIBRARY_CONFIG, WolkieLibraryConfig } from '@itwolkie/wolkie-library';

const wolkieLibraryConfig: WolkieLibraryConfig = {
  apis: {
    review: environment.apis.review,
    socket: environment.apis.socket
  }
}

if (environment.production) {
  enableProdMode();
}

export function HttpLoaderFactory(httpClient: HttpBackend) {
  return new MultiTranslateHttpLoader(httpClient, [
    { prefix: './assets/i18n/', suffix: `.json?dt=${new Date().toISOString().replace(/\.|:|-/g, '')}` },//host app i18n assets
    { prefix: './wolkie-library-assets/i18n/', suffix: `.json?dt=${new Date().toISOString().replace(/\.|:|-/g, '')}` }, //your lib assets
  ]);

  // return new TranslateHttpLoader(httpClient, './assets/i18n/', `.json?dt=${new Date().toISOString().replace(/\.|:|-/g,'')}`);
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: WOLKIE_LIBRARY_CONFIG, useValue: wolkieLibraryConfig},
    importProvidersFrom(IonicModule.forRoot({})),
    provideRouter(routes),
    provideHttpClient(withInterceptors([tokenInterceptor])),
    importProvidersFrom(TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpBackend]
        }
    })),
    provideNoopAnimations(),
    provideAnimations()
  ],
});
