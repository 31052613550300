import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponseBody } from '../models/api-response';
import { GetReview } from '../models/dtos/get-review';
import { MarkupSpecs } from '../models/dtos/markup-specs';
import { GenerateReviewToken } from '../models/generate-review-token';
import { CreateMarkupRequest } from '../models/requests/create-markup';
import { ViewMarkup } from '../models/views/view-markup';
import { ShareMediaWithUserAvatar } from '../models/media-with-user-avatar';
import { FileResponse } from '../models/file-response';
import { DownloadFolderResponse } from '../models/download-folder-response';
import { Share } from '../models/share';
import { MediaWithUserAvatar } from '../models/dtos/media-with-user-avatar';
import { UpdateMediaRequest } from '../models/media';
import { ReviewRoom } from '../models/review-room';
import { WorkspaceBrandingWithLogoAndBranding } from '../models/responses/workspace-branding-logo';

@Injectable({
  providedIn: 'root'
})
export class ReviewApiService {
  private apiUrl = environment.apis.review;

  constructor(
    private http: HttpClient
  ) { }

  getUnidentifiedToken(shareId: string): Promise<ApiResponseBody<{token: string}>> {
    return firstValueFrom(this.http.get<ApiResponseBody<{token: string}>>(`${this.apiUrl}/token/unidentified-token/${shareId}`) as Observable<ApiResponseBody<{token: string}>>);
  }

  getUnidentifiedTokenByUrlId(urlId: string): Promise<ApiResponseBody<{shareId: string; active: boolean; expireDate?: Date}>> {
    return firstValueFrom(this.http.get<ApiResponseBody<{shareId: string}>>(`${this.apiUrl}/token/unidentified-token-by-urlId/${urlId}`) as Observable<ApiResponseBody<{shareId: string; active: boolean; expireDate?: Date}>>);
  }

  identifyUser(model: GenerateReviewToken): Promise<ApiResponseBody<{token: string}>> {
    return firstValueFrom(this.http.post<ApiResponseBody<{token: string}>>(`${this.apiUrl}/token/identify-user`, model));
  }

  getReview(mediaId: string): Promise<ApiResponseBody<GetReview>> {
    return firstValueFrom(this.http.get<ApiResponseBody<GetReview>>(`${this.apiUrl}/${mediaId}`));
  }

  getBrand(): Promise<ApiResponseBody<WorkspaceBrandingWithLogoAndBranding>> {
    return firstValueFrom(this.http.get<ApiResponseBody<any>>(`${this.apiUrl}/branding`));
  }

  getShare() {
    return firstValueFrom(this.http.get<ApiResponseBody<any>>(`${this.apiUrl}/share`));
  }

  getShareMedias(): Promise<ApiResponseBody<ShareMediaWithUserAvatar[]>> {
    return firstValueFrom(this.http.get<ApiResponseBody<any>>(`${this.apiUrl}/share/medias`));
  }

  getMediaFiles(mediaId: string): Promise<ApiResponseBody<FileResponse[]>> {
    return firstValueFrom(this.http.get<ApiResponseBody<any>>(`${this.apiUrl}/medias/${mediaId}/files`));
  }

  updateMediaStatus(mediaId: string, statusOption: string): Promise<ApiResponseBody<ShareMediaWithUserAvatar>> {
    return firstValueFrom(this.http.put<ApiResponseBody<any>>(`${this.apiUrl}/medias/${mediaId}/status`, {status: statusOption}));
  }

  updateShareStatus(statusOption: string): Promise<ApiResponseBody<Share>> {
    return firstValueFrom(this.http.put<ApiResponseBody<Share>>(`${this.apiUrl}/share/status`, {status: statusOption}));
  }

  updateMedia(mediaId: string, body: UpdateMediaRequest): Promise<ApiResponseBody<MediaWithUserAvatar>> {
    return firstValueFrom(this.http.put<ApiResponseBody<MediaWithUserAvatar>>(`${this.apiUrl}/medias/${mediaId}`, body))
  }

  downloadFolder(folderId: string): Promise<ApiResponseBody<DownloadFolderResponse[]>> {
     return firstValueFrom(this.http.get<ApiResponseBody<any>>(`${this.apiUrl}/download-folder/${folderId}`));
  }

  getVersions(mediaId: string): Promise<ApiResponseBody<any>> {
    return firstValueFrom(this.http.get<ApiResponseBody<any>>(`${this.apiUrl}/versions/${mediaId}`));
  }

  createMarkup(mediaId: string, body: CreateMarkupRequest): Promise<ApiResponseBody<ViewMarkup>> {
    return firstValueFrom(this.http.post<ApiResponseBody<ViewMarkup>>(`${this.apiUrl}/${mediaId}/markups`, body))
  }

  createComment(markupId: string, text: string): Promise<ApiResponseBody<ViewMarkup>> {
    return firstValueFrom(this.http.post<ApiResponseBody<ViewMarkup>>(`${this.apiUrl}/markups/${markupId}/comments`, {text}))
  }

  updateTextComment(markupId: string, commentId: string, text: string): Promise<ApiResponseBody<ViewMarkup>> {
    return firstValueFrom(this.http.put<ApiResponseBody<ViewMarkup>>(`${this.apiUrl}/markups/${markupId}/comments/${commentId}/text`, {text}));
  }

  deleteComment(markupId: string, commentId: string): Promise<ApiResponseBody<ViewMarkup>> {
    return firstValueFrom(this.http.delete<ApiResponseBody<ViewMarkup>>(`${this.apiUrl}/markups/${markupId}/comments/${commentId}`, {}));
  }

  markupUpdateSpecs(mediaId: string, markupId: string, specs: MarkupSpecs): Promise<ApiResponseBody<ViewMarkup>> {
    return firstValueFrom(this.http.put<ApiResponseBody<ViewMarkup>>(`${this.apiUrl}/${mediaId}/markups/${markupId}/update-specs`, specs));
  }

  markupUpdateColor(mediaId: string, markupId: string, color: string): Promise<ApiResponseBody<ViewMarkup>> {
    return firstValueFrom(this.http.put<ApiResponseBody<ViewMarkup>>(`${this.apiUrl}/${mediaId}/markups/${markupId}/update-color`, {color}));
  }

  markupResolved(mediaId: string, markupId: string, resolved: boolean): Promise<ApiResponseBody<ViewMarkup>> {
    return firstValueFrom(this.http.put<ApiResponseBody<ViewMarkup>>(`${this.apiUrl}/${mediaId}/markups/${markupId}/update-resolved`, {resolved}));
  }

  markupDelete(mediaId: string, markupId: string): Promise<ApiResponseBody<ViewMarkup>> {
    return firstValueFrom(this.http.delete<ApiResponseBody<ViewMarkup>>(`${this.apiUrl}/${mediaId}/markups/${markupId}`));
  }

  getRooms(mediaId: string): Promise<ApiResponseBody<ReviewRoom[]>> {
    return firstValueFrom(this.http.get<ApiResponseBody<ReviewRoom[]>>(`${this.apiUrl}/${mediaId}/rooms`));
  }

  createRoom(mediaId: string, name: string): Promise<ApiResponseBody<any>> {
    return firstValueFrom(this.http.post<ApiResponseBody<any>>(`${this.apiUrl}/${mediaId}/rooms`, {name}));
  }

  deleteRoom(mediaId: string, roomId: string): Promise<ApiResponseBody<any>> {
    return firstValueFrom(this.http.delete<ApiResponseBody<any>>(`${this.apiUrl}/${mediaId}/rooms/${roomId}`));
  }
}
