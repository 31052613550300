export const convertPixelsToRem = (px: number): number => {
  return px / parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export const convertRemToPixels = (rem: number): number => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}


export const convertVwToPixels = (vw: number): number => {
  return Math.ceil((window.innerWidth * vw / 100));
}

export const convertVhToPixels = (vh: number): number => {
  return Math.ceil((window.innerHeight * vh / 100));
}

export const convertPixelsToVw = (px: number): number => {
  return (100 * px / window.innerWidth);
}

export const convertPixelsToVh = (px: number): number => {
  return (100 * px / window.innerHeight);
}


