import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { WolkieService } from '@itwolkie/wolkie-library';
import { mobileCheck } from './helpers/modile-check-helper';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule],
})
export class AppComponent {
  constructor(translateService: TranslateService, wolkieService: WolkieService) {
    const browserLang = translateService.getBrowserLang()
    const lang = environment.supportedLangs.find(lang => lang.includes(`${browserLang}-`))
    translateService.setDefaultLang(lang ?? `en-US`);
    translateService.currentLang = translateService.defaultLang;
    wolkieService.setIsMobile(mobileCheck());
  }
}
