import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TextComponent } from '../text/text.component';
import { FormErrorComponent } from '../form-error/form-error-component';
import { TextSize, TextWeight } from 'src/app/models/types/text-types';

@Component({
  selector: 'app-input-checkbox',
  standalone: true,
  imports: [CommonModule, IonicModule, TextComponent, FormErrorComponent, ReactiveFormsModule],
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss']
})
export class InputCheckboxComponent {
  @Input() labelPosition: 'before' | 'after' = 'after';
  @Input() label: string;
  @Input() control: FormControl;
  @Input() labelColor: string = '#464E5F';
  @Input() labelSize: TextSize;
  @Input() labelWeight: TextWeight = 'regular';

  get labelBefore(): boolean {
    return !!(this.label && this.labelPosition === `before`);
  }

  get labelAfter(): boolean {
    return !!(this.label && !this.labelBefore);
  }

  toggleValue() {
    this.control.setValue(!this.control.value);
    this.control.updateValueAndValidity();
  }
}
