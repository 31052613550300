<div [ngSwitch]="type">
  <app-input-text
    *ngSwitchCase="'text'"
    [label]="label"
    [type]="textType"
    [enterKeyHint]="enterKeyHint"
    [control]="control"
    [placeholder]="placeholder"
    [inputMode]="inputMode"
    (enterPressed)="onEnterPressed($event)"
    (focusEvent)="onFocus.emit()"
    (blurEvent)="onBlur.emit()"
    [isRequired]="isRequired"
    [labelColor]="labelColor"
    [labelSize]="labelSize"
    [labelWeight]="labelWeight"
    [icon]="icon"
  >
  </app-input-text>

  <app-input-textarea
    *ngSwitchCase="'textarea'"
    [label]="label"
    [type]="textType"
    [enterKeyHint]="enterKeyHint"
    [control]="control"
    [placeholder]="placeholder"
    [inputMode]="inputMode"
    (enterPressed)="onEnterPressed($event)"
    (focusEvent)="onFocus.emit()"
    (blurEvent)="onBlur.emit()"
    [isRequired]="isRequired"
    [labelColor]="labelColor"
    [labelSize]="labelSize"
    [labelWeight]="labelWeight"
    [icon]="icon"
  >
  </app-input-textarea>

  <app-input-checkbox
    *ngSwitchCase="'checkbox'"
    [label]="label"
    [control]="control"
    [labelColor]="labelColor"
    [labelSize]="labelSize"
    [labelWeight]="labelWeight"
  >
  </app-input-checkbox>
</div>
