import { Subject } from "rxjs";
import { SocketEvent, SOCKET_EVENT_ACTION } from "src/app/models/socket-event";
import { environment } from "src/environments/environment";

export class SocketHelper {
  private mediaId: string;
  private socket: WebSocket;
  socketEvent$ = new Subject<SocketEvent>();
  private pong = true;
  isOpen = false;
  private closed = false;

  constructor(mediaId: string, token: string) {
    this.mediaId = mediaId;

    this.socket = new WebSocket(`${environment.apis.socket}?v2=1&token=${token}&mediaId=${this.mediaId}`);


    this.socket.addEventListener('close', (ev) => {
      this.closed = true;
      this.isOpen = false;
      this.socketEvent$.next({action: SOCKET_EVENT_ACTION.CLOSE, data: null});
    });
    this.socket.addEventListener('error', (ev) => {
      this.socketEvent$.next({action: SOCKET_EVENT_ACTION.ERROR, data: ev});
    });
    this.socket.addEventListener('message', (ev) => {
      const event: SocketEvent = JSON.parse(ev.data);
      if (event.action === SOCKET_EVENT_ACTION.PONG) {
        this.pong = true;
      } else {
        this.socketEvent$.next(event);
      }
    });
    this.socket.addEventListener('open', (ev) => {
      console.log("🚀 ~ file: socket-helper-v2.ts ~ line 36 ~ SocketHelperV2 ~ this.socket.addEventListener ~ ev", ev)
      this.closed = false;
      this.isOpen = true;
      this.socketEvent$.next({action: SOCKET_EVENT_ACTION.OPEN, data: ev});
      this.pingPong();
    });
  }

  private pingPong() {
    this.pong = false;
    this.sendEvent('ping', 'ping');
    const pingPongInterval = setInterval(() => {
      if (!this.pong) {
        clearInterval(pingPongInterval);
        this.closeConnection();
        return;
      }

      if (this.closed) {
        clearInterval(pingPongInterval);
        return;
      }

      this.sendEvent('ping', 'ping');
    }, 15000);
  }

  sendEvent(action: string, data: any) {
    this.socket.send(JSON.stringify({ action, data }));
  }

  closeConnection() {
    this.socket.close();
  }
}
