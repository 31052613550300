import { HttpInterceptorFn, HttpResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { catchError, of } from "rxjs";
import { AuthService } from "../services/auth.service";
import { WolkieService } from "../services/wolkie.service";
import { TranslateService } from "@ngx-translate/core";

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const wolkieService = inject(WolkieService);
  const translateService = inject(TranslateService);

  const token = authService.token;

  if (token) {
    req = req.clone({
      headers: req.headers.set(`Authorization`, `Bearer ${token}`)
    });
  }

  return next(req).pipe(
    catchError((response: any) => {
      if (response?.error?.translationProperty) {
        wolkieService.toast.error(translateService.instant('Error'), translateService.instant(response?.error?.translationProperty))
          .then(() => true)
      }
      return of(new HttpResponse(response));
    }));
}
