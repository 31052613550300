import { Component, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TextComponent } from '../../shared/text/text.component';
import { ButtonComponent } from '../../shared/button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { WolkieComponent } from 'src/app/wolkie.component';
import { SafeHtmlPipe } from 'src/app/pipes/safe-html.pipe';

@Component({
  selector: 'app-action-confirmation',
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TextComponent,
    ButtonComponent,
    TranslateModule,
    SafeHtmlPipe
  ],
  templateUrl: './action-confirmation.component.html',
  styleUrls: ['./action-confirmation.component.scss']
})
export class ActionConfirmationComponent extends WolkieComponent {
  title: string;
  description: any;
  html: boolean;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  confirmClick() {
    this.dialogService.dismissModal('', 'confirm');
  }

  cancelClick() {
    this.dialogService.dismissModal('', 'cancel');
  }

}
