import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'app-list-thumbnail',
  standalone: true,
  imports: [CommonModule, IonicModule, TextComponent],
  templateUrl: './list-thumbnail.component.html',
  styleUrls: ['./list-thumbnail.component.scss']
})
export class ListThumbnailComponent implements OnInit {
  thumbsArray: string[];
  @Input() type: string;
  @Input() thumbs: string | string[]
  @Input() childrenCount: number;
  @Input() withDuration: boolean = false;
  @Input() duration: string;
  @Input() isTableMode: boolean = false;
  @Input() isMobileMode: boolean = false;
  @Input() isRestoring: boolean = false;
  @Input() isArchived: boolean = false;
  @Input() isAudioMedia: boolean = false;
  @Input() ribbonText: string;

  get thumbIsString() {
    return typeof this.thumbs === 'string';
  }

  get defaultBgPath() {
    return this.isTableMode ? './assets/images/default-white-bg.png' : './assets/images/default-bg.png'
  }


  constructor() { }

  ngOnInit(): void {
    if (this.thumbs && this.thumbs.length > 0) {
      this.thumbsArray = this.thumbs as string[];
    }
    if (this.type === 'folder') {
      this.getFolderThumbs();
    }
  }

  getFolderThumbs() {
    this.thumbs = this.thumbsArray && this.thumbsArray?.length > 0 ? this.thumbsArray.filter((t, idx) => idx < 3) : [];
  }
}
