import { Component, Inject, Injector, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WolkieDialogComponent } from 'src/app/wolkie-dialog.component';
import { InputComponent } from '../../shared/input/input.component';
import { TextComponent } from '../../shared/text/text.component';
import { ButtonComponent } from '../../shared/button/button.component';
import { ShareMediaWithUserAvatar } from 'src/app/models/media-with-user-avatar';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ListThumbnailComponent } from '../../shared/list-thumbnail/list-thumbnail.component';
import { LoadingComponent } from '../../shared/loading/loading.component';
import { TranslateModule } from '@ngx-translate/core';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-view-versions',
  standalone: true,
  imports: [CommonModule, IonicModule, InputComponent, TextComponent, ButtonComponent, MatDialogModule, ListThumbnailComponent, LoadingComponent, TranslateModule],
  templateUrl: './view-versions.component.html',
  styleUrls: ['./view-versions.component.scss']
})
export class ViewVersionsComponent extends WolkieDialogComponent implements OnInit {
  loading = true;
  mediaVersions: ShareMediaWithUserAvatar[] = [];
  version: number[] = [];
  duration: string[] = [];
  mediaId: string;

  constructor(
    injector: Injector,
    private dialogService: DialogService
  ) {
    super(injector);
  }

  async ngOnInit() {
    this.mediaVersions = (await this.reviewApiService.getVersions(this.mediaId)).data.reverse();
    this.mediaVersions.map((media, ind) => {
      this.version[ind] = this.mediaVersions.length - ind;
      if(media.duration) {
        this.duration[ind] = new Date((media.duration || 0) * 1000).toISOString().substr(11, 8);
      }
    });
    this.loading = false;
  }

  goToCompare() {
    this.dialogService.dismissModal();
    this.shareLibraryService.navigateToCompareVersion(this.mediaId);
  }
}
