import { Component, Inject, Injector, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HelpVideosComponent } from '../../shared/help-videos/help-videos.component';
import { TextComponent } from '../../shared/text/text.component';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-help-videos-modal',
  standalone: true,
  imports: [CommonModule, IonicModule, HelpVideosComponent, TextComponent],
  templateUrl: './help-videos-modal.component.html',
  styleUrls: ['./help-videos-modal.component.scss']
})
export class HelpVideosModalComponent {
  isMobile: boolean;
  hasBranding: boolean;

  constructor(
    private dialogService: DialogService
  ) {
  }

  closeModal() {
    this.dialogService.dismissModal();
  }
}
