<div class="modal-identifier-wrapper" [class.password-required]="passwordIsRequired">
  <div class="modal-identifier-header">
    <app-text [type]="'heading'" [label]="'identifierModal.header'" [weight]="'medium'" [size]="'md-text'"></app-text>
    <app-text [label]="'identifierModal.description'" [size]="'xs'"></app-text>
  </div>
  <div class="modal-identifier-body">
    <app-input [type]="'text'" [textType]="'text'" [control]="name" [placeholder]="'inputPlaceholder.name'" (onFocus)="onFocus()" (onBluc)="onBlur()"></app-input>
    <app-input [type]="'text'" [textType]="'email'" [control]="email" [placeholder]="'inputPlaceholder.email'" (onFocus)="onFocus()" (onBluc)="onBlur()"></app-input>
    <app-input *ngIf="passwordIsRequired" [type]="'text'" [textType]="'password'" [control]="password" [placeholder]="'inputPlaceholder.accessCode'" (onFocus)="onFocus()" (onBluc)="onBlur()"></app-input>
    <!-- <app-text [tagType]="'hyperText'" [type]="'link'" [redirectUrl]="'#'" [label]="'DoesntReceiveAccessCode'" [size]="'xs'"></app-text> -->
    <app-text class="text-color" [label]="'chooseAvatarColor'" [size]="'xs'"></app-text>
    <div class="color-wrapper">
      <div class="color"
        *ngFor="let avatarColor of avatarColors"
        [style.background-color]="avatarColor"
        [class.is-selected]="avatarColor === avatarColorSelected"
        (click)="selectColor(avatarColor)"
      >
        <span>{{getAvatarText()}}</span>
      </div>
    </div>
    <app-input [type]="'checkbox'" [control]="termsAgreementAndPolice" [label]="'termsAgreementAndPolice'"></app-input>
    <app-button [label]="'Confirm' | translate" [expand]="'full'" (click)="submitForm()" [loading]="loading"></app-button>
  </div>
</div>
