import { Component, Injector, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { TextSize, TextWeight } from 'src/app/models/types/text-types';

@Component({
  selector: 'app-text',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule],
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent {
  @Input() label: string;
  @Input() type: `heading` | `paragraph` | 'link' | 'error' = 'paragraph';
  @Input() tagType: 'text' | 'hyperText' = 'text';
  @Input() redirectUrl: string;
  @Input() color: string = '#464E5F';
  @Input() size: TextSize;
  @Input() weight: TextWeight = 'regular';
}
