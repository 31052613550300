import { Injectable } from '@angular/core';
import { ToastButton, ToastController, ToastOptions } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class WolkieService {
  private readonly toastCloseIcon = '/assets/svg/toast/close.svg';

  isMobile: boolean = false;

  get toast() {
    return {
      success: (header: string, message: string, duration = 3000, position: 'top' | 'bottom' | 'middle' = 'top', extraCssClass = '') => this.openToast({header, message, duration, position, cssClass: `wk-toast-success ${extraCssClass}`, buttons: [{side: 'start',icon: '/assets/svg/toast/success.svg'}, {side: 'end', icon: this.toastCloseIcon}]}),
      error: (header: string, message: string, duration = 3000, position: 'top' | 'bottom' | 'middle' = 'top', extraCssClass = '') => this.openToast({header, message, duration, position, cssClass: `wk-toast-error ${extraCssClass}`, buttons: [{side: 'start',icon: '/assets/svg/toast/error.svg'}, {side: 'end', icon: this.toastCloseIcon}]}),
      info: (header: string, message: string, duration = 3000, position: 'top' | 'bottom' | 'middle' = 'top', extraCssClass = '') => this.openToast({header, message, duration, position, cssClass: `wk-toast-info ${extraCssClass}`, buttons: [{side: 'start',icon: '/assets/svg/toast/info.svg'}, {side: 'end', icon: this.toastCloseIcon}]}),
      warning: (header: string, message: string, duration = 3000, position: 'top' | 'bottom' | 'middle' = 'top', extraCssClass = '') => this.openToast({header, message, duration, position, cssClass: `wk-toast-warning ${extraCssClass}`, buttons: [{side: 'start',icon: '/assets/svg/toast/warning.svg'}, {side: 'end', icon: this.toastCloseIcon}]}),
      open: (opts?: ToastOptions) => this.openToast(opts)
    };
  }

  constructor(
    private toastController: ToastController
  ) {}

  setIsMobile(isMobile: boolean) {
    this.isMobile = isMobile;
  }

  async openToast(opts?: ToastOptions): Promise<HTMLIonToastElement> {
    const toast = await this.toastController.create(opts);
    toast.present();

    return toast;
  }
}
