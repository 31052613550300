  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'project'">
      <div class="project-thumbnail img-wrapper" *ngIf="thumbsArray && thumbsArray.length > 0" [ngClass]="thumbsArray.length === 3 ? 'three-thumbs' : thumbsArray.length === 2 ? 'two-thumbs' : ''">
        <div class="file-thumb-block" *ngFor="let thumb of thumbsArray; let i = index " [ngClass]="i === 1 ? 'second-img' : i === 2 ? 'third-img' : ''">
          <img *ngIf="thumb" class="file-thumb-img" [src]="thumb" (error)="thumb === null" />
        </div>
        <div class="img-overlay" *ngIf="childrenCount > 3 && thumbsArray.length >= 3">
          <span>+{{childrenCount - 3}}</span>
        </div>
      </div>
      <div class="img-wrapper with-no-thumbs" *ngIf="!thumbsArray || !thumbsArray.length">
        <div class="file-thumb-block">
        </div>
        <div class="file-thumb-block second-img">
        </div>
        <div class="file-thumb-block third-img">
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'file'">
      <div class="files-thumbnail thumb-wrapper" [class.is-table]="isTableMode" [class.is-mobile-mode]="isMobileMode">
        <ng-container *ngIf="thumbs">
          <ng-container *ngIf="!isAudioMedia">
            <img *ngIf="isTableMode" [src]="thumbIsString ? thumbs : thumbs[0]" class="img" width="100%"
              onerror="this.src='./assets/images/default-white-bg.png'">
            <img *ngIf="!isTableMode" [src]="thumbIsString ? thumbs : thumbs[0]" class="img" width="100%"
              onerror="this.src='./assets/images/default-bg.png'">
          </ng-container>
          <ng-container *ngIf="isAudioMedia">
            <img [src]="'./assets/images/file-card-wave.png'" class="img" width="100%">
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!thumbs">
            <img *ngIf="isTableMode" [src]="'./assets/images/default-white-bg.png'" class="img" width="100%">
            <img *ngIf="!isTableMode" [src]="'./assets/images/default-bg.png'" class="img" width="100%">
        </ng-container>
        <div id="file-duration" class="px-2 py-1" *ngIf="withDuration && duration">
          <app-text [label]="duration" [type]="'paragraph'" [size]="'xxs'" [color]="'#F3F6F9'"></app-text>
        </div>
        <div class="ribbon-wrapper" *ngIf="isRestoring || isArchived">
          <app-text class="ribbon" [label]="ribbonText" [type]="'paragraph'" [size]="'xxs'" [color]="'#fff'"></app-text>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'file_version'">
      <div class="files-thumbnail thumb-wrapper" [class.is-table]="isTableMode">
        <ng-container *ngIf="thumbs">
          <ng-container *ngIf="!isAudioMedia">
            <img *ngIf="isTableMode" [src]="thumbIsString ? thumbs : thumbs[0]" class="img" width="100%"
              onerror="this.src='./assets/images/default-white-bg.png'">
            <img *ngIf="!isTableMode" [src]="thumbIsString ? thumbs : thumbs[0]" class="img" width="100%"
              onerror="this.src='./assets/images/default-bg.png'">
          </ng-container>
          <ng-container *ngIf="isAudioMedia">
            <img [src]="'./assets/images/file-card-wave.png'" class="img" width="100%">
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!thumbs">
          <img *ngIf="isTableMode" [src]="'./assets/images/default-white-bg.png'" class="img" width="100%">
          <img *ngIf="!isTableMode" [src]="'./assets/images/default-bg.png'" class="img" width="100%">
        </ng-container>
        <div id="file-duration" class="px-2 py-1" *ngIf="withDuration">
          <app-text [label]="duration" [type]="'paragraph'" [size]="'xxs'" [color]="'#F3F6F9'"></app-text>
        </div>
        <div class="ribbon-wrapper" *ngIf="isRestoring || isArchived">
          <app-text class="ribbon" [label]="ribbonText" [type]="'paragraph'" [size]="'xxs'" [color]="'#fff'"></app-text>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'folder'">
      <div class="folder-card-thumbnail" [class.is-table]="isTableMode" [class.is-mobile-mode]="isMobileMode">
        <div class="thumbnail-vertical-wrapper">
          <div class="thumbnail-vertical" [style.display]="thumbs[0] ? 'flex' : 'block'">
            <img *ngIf="isTableMode" [src]="thumbs[0]" class="img" width="100%" onerror="this.src='./assets/images/default-white-bg.png'">
            <img *ngIf="!isTableMode"[src]="thumbs[0]" class="img" width="100%" onerror="this.src='./assets/images/default-bg.png'">
          </div>
        </div>
        <div class="thumbanail-horizontal-wrapper">
          <div class="img-horizontal" [style.display]="thumbs[1] ? 'flex' : 'block'">
            <img *ngIf="isTableMode" [src]="thumbs[1]" class="img" width="100%"
              onerror="this.src='./assets/images/default-white-bg.png'">
            <img *ngIf="!isTableMode" [src]="thumbs[1]" class="img" width="100%"
              onerror="this.src='./assets/images/default-bg.png'">
          </div>
          <div class="img-horizontal" [style.display]="thumbs[2] ? 'flex' : 'block'">
            <img *ngIf="isTableMode" [src]="thumbs[2]" class="img" width="100%"
              onerror="this.src='./assets/images/default-white-bg.png'">
            <img *ngIf="!isTableMode" [src]="thumbs[2]" class="img" width="100%"
              onerror="this.src='./assets/images/default-bg.png'">
            <app-text class="total" *ngIf="childrenCount > 3" [label]="'+' + (childrenCount - 3).toString()" [type]="'paragraph'" [size]="isTableMode ? 'xxs' : 'xs' " [color]="'#F3F6F9'" [weight]="'bold'"></app-text>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
