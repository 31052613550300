import { ChangeDetectorRef, Component, Injector, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ActionSheetController, ActionSheetOptions, LoadingController, ModalController } from "@ionic/angular";
import { MonoTypeOperatorFunction, Subject, takeUntil } from "rxjs";
import { ShareLibraryService } from "./services/share-library.service";
import { AuthService } from "./services/auth.service";
import { ReviewApiService } from "./services/review-api.service";
import { ReviewService } from "./services/review.service";
import { TranslateService } from "@ngx-translate/core";
import { FormHelperService } from "./services/form-helper.service";
import { WolkieService } from "./services/wolkie.service";

@Component({
  template: ``
})
export abstract class WolkieDialogComponent implements OnDestroy {
  unsubscriber$ = new Subject();
  loadingController: LoadingController;
  activatedRoute: ActivatedRoute;
  router: Router;
  shareLibraryService: ShareLibraryService;
  authService: AuthService;
  reviewService: ReviewService;
  reviewApiService: ReviewApiService;
  translateService: TranslateService;
  modalController: ModalController;
  formHelper: FormHelperService;
  wolkieService: WolkieService;
  cdRef: ChangeDetectorRef;
  unsubscribe$ = new Subject();
  actionSheetController: ActionSheetController;

  private loader: HTMLIonLoadingElement | undefined;

  constructor(injector: Injector) {
    this.activatedRoute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.router = injector.get<Router>(Router);
    this.loadingController = injector.get<LoadingController>(LoadingController);
    this.shareLibraryService = injector.get<ShareLibraryService>(ShareLibraryService);
    this.authService = injector.get<AuthService>(AuthService);
    this.reviewService = injector.get<ReviewService>(ReviewService);
    this.reviewApiService = injector.get<ReviewApiService>(ReviewApiService);
    this.modalController = injector.get<ModalController>(ModalController);
    this.translateService = injector.get<TranslateService>(TranslateService);
    this.formHelper = injector.get<FormHelperService>(FormHelperService);
    this.wolkieService = injector.get<WolkieService>(WolkieService);
    this.cdRef = injector.get<ChangeDetectorRef>(ChangeDetectorRef);
    this.actionSheetController = injector.get<ActionSheetController>(ActionSheetController);
  }

  takeUntilDestroy<T>(): MonoTypeOperatorFunction<T> {
    return takeUntil(this.unsubscriber$);
  }

  async showLoader(message?: string) {
    this.loader = await this.loadingController.create({
      message
    });
    this.loader.present();
  }

  async hideLoader() {
    if (this.loader) {
      this.loader.dismiss();
      this.loader = undefined;
    }
  }

  translate(key: string, params?: Object | undefined) {
    return this.translateService.instant(key, params);
  }

  ngOnDestroy() {
    this.unsubscriber$.next(null);
    this.unsubscriber$.complete();
  }

  async openActionSheet(opts?: ActionSheetOptions): Promise<HTMLIonActionSheetElement> {
    const actionSheet = await this.actionSheetController.create(opts);

    actionSheet.present();

    return actionSheet;
  }
}
