import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule, IonicModule, TextComponent],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() color: 'primary' | 'secondary' | 'tertiary' | `danger` = 'primary';
  @Input() label: string;
  @Input() expand: 'block' | 'full' | undefined;
  @Input() loading = false;
  @Input() disabled = false;
  @Input() fill: 'outline' | 'clear' | 'solid' = 'solid';
  @Input() iconPath: string;
  @Output() clickEvent = new EventEmitter();

  get buttonText() {
    return this.loading ? `` : this.label;
  }
}
