import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FormErrorComponent } from '../form-error/form-error-component';
import { TranslateModule } from '@ngx-translate/core';
import { TextComponent } from '../text/text.component';
import { TextSize, TextWeight } from 'src/app/models/types/text-types';
import { InputType } from 'src/app/models/types/input-types';

@Component({
  selector: 'app-input-text',
  standalone: true,
  imports: [CommonModule, IonicModule, FormErrorComponent, ReactiveFormsModule, TranslateModule, TextComponent],
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent {
  @Output() blurEvent = new EventEmitter();
  @Output() changeEvent = new EventEmitter<any>();
  @Output() focusEvent = new EventEmitter<any>();
  @Output() inputEvent = new EventEmitter();
  @Output() keyPressed = new EventEmitter();
  @Output() enterPressed = new EventEmitter();

  @Input() type: InputType =  'text';
  @Input() enterKeyHint: 'done' | 'enter' | 'go' | 'next' | 'previous' | 'search' | 'send' | undefined;
  @Input() control: FormControl;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() disableInputIcon = false;
  @Input() isRequired = false;
  @Input() inputMode: `decimal` | `email` | `none` | `numeric` | `search` | `tel` | `text` | `url` | undefined;
  @Input() icon: boolean = true;
  @Input() labelColor: string = '#464E5F';
  @Input() labelSize: TextSize;
  @Input() labelWeight: TextWeight = 'regular';

  get hasError() {
    return this.control.errors && this.control.invalid && this.control.touched;
  };

  get showValidIcon() {
    return !this.disableInputIcon && (this.control.touched || this.control.dirty) && this.control.valid;
  }

  get passwordType() {
    return this.type === 'password';
  }

  onBlur(event: Event) {
    this.blurEvent.emit(event);
  }

  onChange(event: Event) {
    this.changeEvent.emit(event);
  }

  onFocus(event: Event) {
    this.focusEvent.emit(event);
  }

  onInput(event: Event) {
    this.inputEvent.emit(event);
  }

  onKeyPress(event: KeyboardEvent) {
    if (event && (event.key.toLowerCase() === `enter` || event.code.toLowerCase() === `enter`)) {
      this.enterPressed.emit(event);
    }
  }
}
