import { Component, Inject, Injector, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WolkieDialogComponent } from 'src/app/wolkie-dialog.component';
import { InputComponent } from '../../shared/input/input.component';
import { TextComponent } from '../../shared/text/text.component';
import { ButtonComponent } from '../../shared/button/button.component';
import { ShareMediaWithUserAvatar } from 'src/app/models/media-with-user-avatar';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { WolkieComponent } from 'src/app/wolkie.component';

@Component({
  selector: 'app-create-live-review-room',
  standalone: true,
  imports: [CommonModule, IonicModule, InputComponent, TextComponent, ButtonComponent, TranslateModule],
  templateUrl: './create-live-review-room.component.html',
  styleUrls: ['./create-live-review-room.component.scss']
})
export class CreateLiveReviewRoomComponent extends WolkieComponent {
  loading = false;
  form = this.fb.group({
    name: [null, Validators.required],
  });

  get name(): FormControl {
    return this.form.get('name') as FormControl;
  }

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  async submitForm() {
    this.loading = true;
    await this.reviewApiService.createRoom(this.reviewService.media!.id, this.name.value);
    this.loading = false;
    this.dialogService.dismissModal();
  }
}
