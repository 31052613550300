<div *ngIf="control && control.invalid && control.touched && !errorMessage"
  [class.error-form]="control.invalid && control.touched"
  class="invalid-feedback">
  <app-text *ngIf="control.errors && control.errors['required']" [label]="'Required'" [type]="'error'"></app-text>
  <app-text *ngIf="control.errors && control.errors['email']" [label]="'InvalidEmail'" [type]="'error'"></app-text>
</div>

<!-- <div class="invalid-feedback error-form" *ngIf="errorMessage">
  <ion-label>{{errorMessage}}</ion-label>
</div> -->
