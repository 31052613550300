<div class="modal-wrapper">
  <div class="modal-header">
    <app-text [type]="'heading'" [label]="'createRoom'" [weight]="'semi-bold'" [size]="'xs'" [color]="'#464E5F'"></app-text>
    <ion-icon [src]="'assets/svg/modal/close-dialog.svg'" class="svg-icon" (click)="modalController.dismiss();"></ion-icon>
  </div>
  <div class="modal-body">
    <div class="form-wrapper">
      <app-text [label]="'nameRoom'" [size]="'xs'"></app-text>
      <app-input
        class="info-input"
        [control]="name"
        [labelSize]="'xs'"
        [labelWeight]="'bold'"
        [icon]="false">
      </app-input>
    </div>
    <div class="btn-wrapper">
      <app-button [label]="'Cancel' | translate" [expand]="'full'" [color]="'secondary'" (click)="modalController.dismiss();"></app-button>
      <app-button [label]="'Confirm' | translate" [expand]="'full'" [disabled]="!form.valid" (click)="submitForm()" [loading]="loading"></app-button>
    </div>
  </div>
</div>
