<div class="loading" *ngIf="loading">
  <app-loading></app-loading>
</div>
<div class="modal-view-versions-wrapper" *ngIf="!loading">
  <div class="modal-view-versions-header">
    <app-text [type]="'heading'" [label]="'viewVersions.header'" [weight]="'semi-bold'"></app-text>
    <ion-icon [src]="'assets/svg/modal/close-dialog.svg'" class="svg-icon" (click)="modalController.dismiss();"></ion-icon>
  </div>
  <div class="modal-view-versions-body">
    <div class="view-versions-row" *ngFor="let mediaVersion of mediaVersions; let ind = index">
      <div class="version-wrapper">
        <app-text [label]="'v' + version[ind]" [type]="'paragraph'" [size]="'xs'" [color]="'#464E5F'"></app-text>
      </div>
      <div class="thumb-wrapper">
        <app-list-thumbnail
          [type]="mediaVersion.type"
          [thumbs]="mediaVersion && mediaVersion.thumbs || []"
          [childrenCount]="mediaVersion.childrenCount"
          [isTableMode]="true"
          [isArchived]="mediaVersion.archiveStatus?.trim() === 'ARCHIVED'"
          [isRestoring]="mediaVersion.archiveStatus?.trim() === 'RESTORING'"
          [isAudioMedia]="mediaVersion.mediaType === 'audio'"
          [ribbonText]="mediaVersion.type === 'file_version' && mediaVersion.archivedVersions! > 0 ?
            (mediaVersion.archivedVersions + ' / ' + mediaVersion.version + ' ' + ('archived' | translate)) :
            ((mediaVersion.archiveStatus === 'ARCHIVED' ? 'archived' : 'restoring') | translate)
          "
          style="width: 100%;"
        ></app-list-thumbnail>
      </div>
      <div class="text-wrapper">
        <app-text [label]="mediaVersion.name" [type]="'paragraph'" [color]="'#464E5F'" [size]="'xs'"></app-text>
      </div>
      <div class="duration" *ngIf="mediaVersion.duration">
        <app-text [label]="duration[ind]" [type]="'paragraph'" [size]="'xs'" [color]="'#00c5ee'"></app-text>
      </div>
    </div>
    <div class="compare-wrapper" *ngIf="shareLibraryService.share.allowCompare">
      <div class="form-group wrapper-text-input">
        <app-text class="compare-description" [weight]="'semi-bold'"  [label]="'viewVersions.goToComparison'" [type]="'paragraph'" [size]="'xs'" [color]="'#00c5ee'" (click)="goToCompare()"></app-text>
      </div>
    </div>
  </div>
</div>
