import { Injectable } from '@angular/core';
import { Subject, catchError, filter, of, take, takeUntil } from 'rxjs';
import { GetReview } from '../models/dtos/get-review';
import { MarkupSpecs } from '../models/dtos/markup-specs';
import { UserAvatar, UserAvatarWithConnectionId } from '../models/dtos/user-avatar';
import { ToolSettings } from '../models/tool-settings';
import { DrawToolType } from '../models/types/draw-tool-types';
import { ViewMarkup } from '../models/views/view-markup';
import { ReviewApiService } from './review-api.service';
import { WolkieService } from './wolkie.service';
import { PLAYER_SPACING } from 'src/app/models/enums/player-spacing';
import { convertPixelsToVh, convertRemToPixels } from './../helpers/rem-helper';
import { MediaWithUserAvatar } from '../models/dtos/media-with-user-avatar';
import { LIVE_REVIEW_ACTION, LIVE_REVIEW_EVENT } from '../models/live-review-event';
import { ReviewRoom } from '../models/review-room';
import { AuthService } from './auth.service';
import { SocketHelper } from '../helpers/socket-helper';
import { SOCKET_EVENT_ACTION } from '../models/socket-event';
import { FormControl, UntypedFormBuilder } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {
  liveReviewConnectionId: string;
  liveReviewEvent$ = new Subject<LIVE_REVIEW_EVENT>();
  roomMembers: UserAvatarWithConnectionId[] = [];
  currentRoom: ReviewRoom | null;
  rooms: ReviewRoom[] = [];
  versionChanged$: Subject<boolean> = new Subject<boolean>();
  isMediaVersion: boolean = false;
  socket: SocketHelper;
  markupsUpdated$ = new Subject();
  users: UserAvatar[];

  selectedMarkup$: Subject<ViewMarkup | null> = new Subject<ViewMarkup | null>();

  fullscreen$: Subject<boolean> = new Subject<boolean>();

  imgPlayerReady$: Subject<boolean> = new Subject<boolean>();

  isShareReview: boolean = false;

  inputFocus: boolean = false;

  reviewRect: DOMRect;
  selectedMarkup: ViewMarkup | null = null;

  shareId: string;

  toolsSettings: ToolSettings = {
    tool: 'select',
    color: '#FF7F00'
  };

  review?: GetReview;
  versions: any[] = [];

  keyboardOpen: boolean = false;

  currentVersion: {id: string; name: string};

  fullscreen: boolean = false;
  desktopReviewWidth: number = PLAYER_SPACING.REVIEW_DEFAULT_WIDTH;
  desktopReviewHeight: number = PLAYER_SPACING.REVIEW_DEFAULT_HEIGHT;

  appReviewWidth: number = PLAYER_SPACING.APP_REVIEW_DEFAULT_WIDTH;
  appReviewHeight: number = PLAYER_SPACING.APP_REVIEW_DEFAULT_HEIGHT;

  unsubscribe$: Subject<any>;

  form = this.fb.group({
    showMarkups: [true, []]
  });

  get showMarkups(): FormControl {
    return this.form.get('showMarkups') as FormControl;
  }

  set returnUrl (url: string) {
    localStorage.setItem(`returnUrl`, url);
  }

  get returnUr(): string {
    return localStorage.getItem(`returnUrl`)!;
  }

  get markups(): ViewMarkup[] {
    return this.review?.markups ?? [];
  }

  get reviewWidth(): number {
    return this.wolkieService.isMobile ? this.appReviewWidth : this.desktopReviewWidth;
  }

  get reviewHeight(): number {
    return this.wolkieService.isMobile ? this.getMobileReviewHeight() : this.desktopReviewHeight;
  }

  get infoReviewHeight(): number {
    return PLAYER_SPACING.INFO_REVIEW_HEIGHT;
  }
  get infoReviewAppHeight(): number {
    return this.getInfoReviewHeight();
  }

  get appReviewHeightStr(): string {
    return `${this.getMobileReviewHeight()}${this.fullscreen ? 'px' : 'vh'}`;
  }

  get media(): MediaWithUserAvatar | null {
    return this.review?.media ?? null;
  }

  get isArchived(): boolean {
    return (this.media?.archiveStatus ?? '') === 'ARCHIVED';
  }

  constructor(
    private reviewApiService: ReviewApiService,
    private wolkieService: WolkieService,
    private authService: AuthService,
    private fb: UntypedFormBuilder
  ) { }

  async startReview(mediaId: string, versionId?: string) {
    await this.stopReview();
    this.unsubscribe$ = new Subject();
    this.setSocket(mediaId);
    this.review = await this.getReview(mediaId);
    this.isMediaVersion = !!versionId;

    this.reviewApiService.getRooms(this.review.media.id)
      .then(res => {
        this.rooms = res.data;
        this.joinToPreviousRoomOnStart();
      });
    if (this.isMediaVersion) {
      this.versions = await (await this.reviewApiService.getVersions(versionId ?? '')).data;
      this.currentVersion = {id: mediaId, name: `${this.review?.media.versionNumber}`};
    }
  }

  private async setSocket(mediaId: string) {
    this.socket = new SocketHelper(mediaId, this.authService.token!);
    this.socket.socketEvent$
      .pipe(takeUntil(this.unsubscribe$),
      catchError(error => {
        console.log(error);
        return of({action: `Error`} as any);
      }))
      .subscribe(ev => {
        switch (ev.action) {
          case SOCKET_EVENT_ACTION.OPEN:
            if (!this.currentRoom && this.rooms.length) {
              this.joinToPreviousRoomOnStart();
            }
            break;
          case SOCKET_EVENT_ACTION.CLOSE:
            if (this.review) {
              this.setSocket(mediaId);
            }
            break;
          case SOCKET_EVENT_ACTION.ADD_UPDATE_MARKUP:
            this.addOrUpdateMarkup(ev.data);
            console.log(ev.data);
            this.markupsUpdated$.next(null);
            break;
          case SOCKET_EVENT_ACTION.DELETE_MARKUP:
            const obj: {markupId: string, shareId?: string} = ev.data;
            this.removeMarkup(obj.markupId);
            break;
          case SOCKET_EVENT_ACTION.UPDATE_REVIEW_ROOMS:
            this.rooms = ev.data;
            break;
          case SOCKET_EVENT_ACTION.UPDATE_ROOM_MEMBERS:
            if (this.currentRoom) {
              this.roomMembers = ev.data;
              this.liveReviewConnectionId = this.roomMembers.find(x => x.self)!.connectionId;
            }
            break;
          case SOCKET_EVENT_ACTION.LIVE_REVIEW:
            this.liveReviewEvent$.next(ev.data.data);
            break;
          default:
            break;
        }
      });
  }

  joinToPreviousRoomOnStart() {
    const previousRoomId = localStorage.getItem('reviewRoom');
    if (previousRoomId) {
      const room = this.rooms.find(r => r.id === previousRoomId);
      if (previousRoomId && room) {
        this.joinRoom(room);
      }
    }
  }

  async joinRoom(room: ReviewRoom) {
    if (this.socket?.isOpen) {
      this.socket.socketEvent$
        .pipe(filter(ev => ev.action === SOCKET_EVENT_ACTION.UPDATE_ROOM_MEMBERS), take(1))
        .subscribe(ev => {
          const user = ev.data.find((x: { self: any; }) => !x.self);
          if (user) {
            this.liveReviewEvent(LIVE_REVIEW_ACTION.ASK_STATE, ev.data.find((x: { self: any; }) => x.self).connectionId, false, [user.connectionId]);
          }
        });
      await this.authService.forceUserToIdentify();
      this.socket.sendEvent(`join-room`, room.id);
      this.currentRoom = room;
      localStorage.setItem('reviewRoom', this.currentRoom.id);
    }
  }

  leaveRoom() {
    if (this.socket?.isOpen) {
      this.socket.sendEvent(`leave-room`, 'leave');
      this.currentRoom = null;
      this.roomMembers = [];
      localStorage.removeItem('reviewRoom');
    }
  }

  liveReviewEvent(action: LIVE_REVIEW_ACTION, data: any, includeSelf = false, connectionIds: string[] | null = null) {
    if (this.socket && this.currentRoom && this.roomMembers.length) {
      const event: LIVE_REVIEW_EVENT = {
        action,
        data,
        caller: this.roomMembers.find(x => x.self)!.connectionId,
        roomId: this.currentRoom.id,
        connectionIds: connectionIds || this.roomMembers.filter(x => !x.self || includeSelf).map(x => x.connectionId)
      };

      if (event.connectionIds.length) {
        this.socket.sendEvent('live-review', event);
      }
    }
  }

  socketCleanMyMarkups() {
    if (this.currentRoom && this.liveReviewConnectionId) {
      this.liveReviewEvent(LIVE_REVIEW_ACTION.CLEAR_MARKUP, this.liveReviewConnectionId);
    }
  }
  async changeVersion(mediaId: string) {
    this.review = await this.getReview(mediaId);
    console.log(this.review);
    this.currentVersion = {id: mediaId, name: `${this.review?.media.versionNumber}`};
    this.versionChanged$.next(true);
  }

  async getReview(mediaId: string) {
    return (await this.reviewApiService.getReview(mediaId)).data;
  }

  async stopReview() {
    this.review = undefined;
    this.versions = [];
    this.setSelectedMarkup(null);
    this.selectTool('select');
    if (this.socket) {
      this.socket.closeConnection();
    }
    if (this.unsubscribe$) {
      this.unsubscribe$.next(true);
      this.unsubscribe$.complete();
    }
  }

  getMobileReviewHeight(): number {
    const header = convertRemToPixels(4);
    const controlBar = convertRemToPixels(4.5);
    const body = window.innerHeight;
    if (this.fullscreen) {
      return body - (header + controlBar);
    }

    return this.appReviewHeight;
  }

  getInfoReviewHeight(): number {
    const body = window.innerHeight;
    const header = convertRemToPixels(4);
    const doMarkupContainer = convertRemToPixels(PLAYER_SPACING.APP_DO_MARKUP_CONTAINER_HEIGHT);

    return body - (header + this.appReviewHeight + doMarkupContainer);
  }

  setSelectedMarkup(markup: ViewMarkup | null, sendEvent: boolean = false) {
    this.selectedMarkup = markup;
    this.selectedMarkup$.next(markup);
  }

  selectTool(tool: DrawToolType) {
    this.toolsSettings.tool = tool;
    if (this.wolkieService.isMobile) {
      this.removeUnsavedMarkup();
      this.setSelectedMarkup(null);
    }
  }

  setToolColor(color: string) {
    this.toolsSettings.color = color;
  }

  removeUnsavedMarkup() {
    if (this.review !== undefined) {
      this.review.markups = this.markups.filter(m => m.id);
    }
  }

  removeMarkup(markupId: string) {
    if (this.review !== undefined) {
      this.review.markups = this.markups.filter(m => m.id !== markupId);
      this.setSelectedMarkup(null);
    }

  }

  addOrUpdateMarkup(markup: ViewMarkup) {
    const index = this.markups.findIndex(m => m.id === markup.id);

    if (index === -1) {
      this.review?.markups.push(markup);
    } else {
      if (this.review !== undefined) {
        this.review.markups[index] = markup;
      }
    }

    this.review!.markups = [...this.review!.markups];

    if (this.selectedMarkup?.id === markup.id) {
      this.setSelectedMarkup(markup.isResolved ? null : markup);
    }

  }

  toggleFullscreen() {
    if (this.wolkieService.isMobile) {
      this.toggleAppFullscreen();
    } else {
      this.toggleDesktopFullscreen();
    }

    this.fullscreen = !this.fullscreen;
    this.fullscreen$.next(true);
  }

  toggleDesktopFullscreen() {
    if (this.fullscreen) {
      this.desktopReviewWidth = PLAYER_SPACING.REVIEW_DEFAULT_WIDTH;
      this.desktopReviewHeight = PLAYER_SPACING.REVIEW_DEFAULT_HEIGHT;
    } else {
      this.desktopReviewWidth = PLAYER_SPACING.REVIEW_FULLSCREEN_WIDTH;
      this.desktopReviewHeight = this.getDesktopFullscreenHeight();
    }
  }

  getDesktopFullscreenHeight() {
    const header = convertRemToPixels(PLAYER_SPACING.BRANDING_HEADER_HEIGHT + PLAYER_SPACING.HEADER_HEIGHT);
    const footer = convertRemToPixels(PLAYER_SPACING.FOOTER_HEIGHT);
    const controlBar = convertRemToPixels(PLAYER_SPACING.CONTROL_BAR_HEIGHT);
    const toolBar = convertRemToPixels(PLAYER_SPACING.TOOL_BAR_HEIGHT + PLAYER_SPACING.TOOL_BAR_MARGIN_TOP);
    const body = window.innerHeight;

    return convertPixelsToVh(body - (header + footer + controlBar + toolBar));
  }

  toggleAppFullscreen() {
    if (this.fullscreen) {
      this.appReviewWidth = PLAYER_SPACING.APP_REVIEW_DEFAULT_WIDTH;
      this.appReviewHeight = PLAYER_SPACING.APP_REVIEW_DEFAULT_HEIGHT;
    } else {
      this.appReviewWidth = PLAYER_SPACING.APP_REVIEW_FULLSCREEN_WIDTH;
      this.appReviewHeight = PLAYER_SPACING.APP_REVIEW_FULLSCREEN_HEIGHT;
    }
  }

  markupUpdateSpecs(mediaId: string, markupId: string, specs: MarkupSpecs) {}

}
