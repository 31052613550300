<div class="help-wrapper" [class.is-mobile]="isMobile">
  <div class="help-title" *ngIf="isMobile && videoType === 'library' ">
    <app-text [label]="'help'" [type]="'heading'" [color]="'#464E5F'" [size]="'xs'"></app-text>
  </div>
  <div class="help-list-wrapper" [class.showing-on-player]="videoType === 'player'">
    <div class="media-wrapper" [class.type-row]="apresentationType === 'row'" *ngFor="let video of videosToShow">
      <ng-container *ngIf="apresentationType === 'column'">
        <div class="media-title">
          <ion-icon class="ion-icon" [src]="'/assets/svg/review/help-icon.svg'"></ion-icon>
          <app-text [label]="video.title" [type]="'paragraph'" [color]="'#464E5F'" [size]="'xs'" [weight]="'semi-bold'"></app-text>
        </div>
        <video class="video-player" [src]="video.src" [poster]="video.thumb" controls webkit-playsinline playsinline></video>
        <app-text [label]="video.description" [type]="'paragraph'" [color]="'#80808F'" [size]="'xxs'"></app-text>
      </ng-container>
      <ng-container *ngIf="apresentationType === 'row'">
        <video class="video-player" [src]="video.src" [poster]="video.thumb" controls webkit-playsinline playsinline></video>
        <div class="media-text-wrapper">
          <app-text [label]="video.title" [type]="'paragraph'" [color]="'#464E5F'" [size]="'xs'" [weight]="'semi-bold'"></app-text>
          <app-text [label]="video.description" [type]="'paragraph'" [color]="'#80808F'" [size]="'xxs'"></app-text>
        </div>
      </ng-container>
    </div>
  </div>
</div>
