import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TextComponent } from '../text/text.component';
import { HelpVideos } from 'src/app/models/help-videos';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-help-videos',
  standalone: true,
  imports: [CommonModule, IonicModule, TextComponent],
  templateUrl: './help-videos.component.html',
  styleUrls: ['./help-videos.component.scss']
})
export class HelpVideosComponent implements OnInit {
  @Input() isMobile: boolean;
  @Input() videoType: 'library' | 'player';
  @Input() apresentationType: 'row' | 'column';
  @Input() hasBranding: boolean;

  orderBy = 'asc';
  videosToShow: HelpVideos[] = [];
  helpVideosWithWolkieLogo: HelpVideos[] = [
    {
      title: 'helpVideos.spacePresentationTitle',
      description: 'helpVideos.spacePresentationDescription',
      src: "https://cdn.mywolkie.com/help-videos/video-help-library-wolkie-1.mp4",
      thumb: this.translateService.instant('helpVideoThumbs.reviewSpace'),
      type: 'library',
      isMobile: false
    },
    {
      title: 'helpVideos.manageStatusTitle',
      description: 'helpVideos.manageStatusDescription',
      src: "https://cdn.mywolkie.com/help-videos/video-help-library-wolkie-2.mp4",
      thumb: this.translateService.instant('helpVideoThumbs.status'),
      type: 'library',
      isMobile: false
    },
    {
      title: 'helpVideos.howToCompareTitle',
      description: 'helpVideos.howToCompareDescription',
      src: "https://cdn.mywolkie.com/help-videos/video-help-library-wolkie-3.mp4",
      thumb: this.translateService.instant('helpVideoThumbs.compareVersions'),
      type: 'library',
      isMobile: false
    },
    {
      title: 'helpVideos.doDownloadTitle',
      description: 'helpVideos.doDownloadDescription',
      src: "https://cdn.mywolkie.com/help-videos/video-help-library-wolkie-4.mp4",
      thumb: this.translateService.instant('helpVideoThumbs.download'),
      type: 'library',
      isMobile: false
    },
    {
      title: 'helpVideos.spacePresentationTitle',
      description: 'helpVideos.spacePresentationDescription',
      src: "https://cdn.mywolkie.com/help-videos/video-help-library-wolkie-mobile-1.mp4",
      thumb: this.translateService.instant('helpVideoThumbs.reviewSpace'),
      type: 'library',
      isMobile: true
    },
    {
      title: 'helpVideos.manageStatusTitle',
      description: 'helpVideos.manageStatusDescription',
      src: "https://cdn.mywolkie.com/help-videos/video-help-library-wolkie-mobile-2.mp4",
      thumb: this.translateService.instant('helpVideoThumbs.status'),
      type: 'library',
      isMobile: true
    },
    {
      title: 'helpVideos.doDownloadTitle',
      description: 'helpVideos.doDownloadDescription',
      src: "https://cdn.mywolkie.com/help-videos/video-help-library-wolkie-mobile-3.mp4",
      thumb: this.translateService.instant('helpVideoThumbs.download'),
      type: 'library',
      isMobile: true
    },
    {
      title: 'helpVideos.howToMakeMarkupTitle',
      description: 'helpVideos.howToMakeMarkupDescription',
      src: "https://cdn.mywolkie.com/help-videos/video-help-player-wolkie-1.mp4",
      thumb: this.translateService.instant('helpVideoThumbs.markup'),
      type: 'player',
      isMobile: false
    },
    {
      title: 'helpVideos.howToMakeMarkupTitle',
      description: 'helpVideos.howToMakeMarkupDescription',
      src: "https://cdn.mywolkie.com/help-videos/video-help-player-wolkie-mobile-1.mp4",
      thumb: this.translateService.instant('helpVideoThumbs.markup'),
      type: 'player',
      isMobile: true
    }
  ]

  helpVideosWithoutWolkieLogo: HelpVideos[] = [
    {
      title: 'helpVideos.spacePresentationTitle',
      description: 'helpVideos.spacePresentationDescription',
      src: "https://cdn.mywolkie.com/help-videos/without-logo/video-help-library-wolkie-1.mp4",
      thumb: this.translateService.instant('helpVideoThumbs.reviewSpace'),
      type: 'library',
      isMobile: false
    },
    {
      title: 'helpVideos.manageStatusTitle',
      description: 'helpVideos.manageStatusDescription',
      src: "https://cdn.mywolkie.com/help-videos/without-logo/video-help-library-wolkie-2.mp4",
      thumb: this.translateService.instant('helpVideoThumbs.status'),
      type: 'library',
      isMobile: false
    },
    {
      title: 'helpVideos.howToCompareTitle',
      description: 'helpVideos.howToCompareDescription',
      src: "https://cdn.mywolkie.com/help-videos/without-logo/video-help-library-wolkie-3.mp4",
      thumb: this.translateService.instant('helpVideoThumbs.compareVersions'),
      type: 'library',
      isMobile: false
    },
    {
      title: 'helpVideos.doDownloadTitle',
      description: 'helpVideos.doDownloadDescription',
      src: "https://cdn.mywolkie.com/help-videos/without-logo/video-help-library-wolkie-4.mp4",
      thumb: this.translateService.instant('helpVideoThumbs.download'),
      type: 'library',
      isMobile: false
    },
    {
      title: 'helpVideos.spacePresentationTitle',
      description: 'helpVideos.spacePresentationDescription',
      src: "https://cdn.mywolkie.com/help-videos/without-logo/video-help-library-wolkie-mobile-1.mp4",
      thumb: this.translateService.instant('helpVideoThumbs.reviewSpace'),
      type: 'library',
      isMobile: true
    },
    {
      title: 'helpVideos.manageStatusTitle',
      description: 'helpVideos.manageStatusDescription',
      src: "https://cdn.mywolkie.com/help-videos/without-logo/video-help-library-wolkie-mobile-2.mp4",
      thumb: this.translateService.instant('helpVideoThumbs.status'),
      type: 'library',
      isMobile: true
    },
    {
      title: 'helpVideos.doDownloadTitle',
      description: 'helpVideos.doDownloadDescription',
      src: "https://cdn.mywolkie.com/help-videos/without-logo/video-help-library-wolkie-mobile-3.mp4",
      thumb: this.translateService.instant('helpVideoThumbs.download'),
      type: 'library',
      isMobile: true
    },
    {
      title: 'helpVideos.howToMakeMarkupTitle',
      description: 'helpVideos.howToMakeMarkupDescription',
      src: "https://cdn.mywolkie.com/help-videos/without-logo/video-help-player-wolkie-1.mp4",
      thumb: this.translateService.instant('helpVideoThumbs.markup'),
      type: 'player',
      isMobile: false
    },
    {
      title: 'helpVideos.howToMakeMarkupTitle',
      description: 'helpVideos.howToMakeMarkupDescription',
      src: "https://cdn.mywolkie.com/help-videos/without-logo/video-help-player-wolkie-mobile-1.mp4",
      thumb: this.translateService.instant('helpVideoThumbs.markup'),
      type: 'player',
      isMobile: true
    }
  ];

  constructor(private translateService: TranslateService) {}


  ngOnInit(): void {
    this.hasBranding = true;
    this.videosToShow = this.helpVideosWithWolkieLogo.filter(videos => videos.isMobile === this.isMobile);
    if(this.hasBranding) {
      this.videosToShow = this.helpVideosWithoutWolkieLogo.filter(videos => videos.isMobile === this.isMobile);
    }
    const orderBy = this.videoType === 'library' ? 'asc' : 'desc';
    this.videosToShow.sort(this.showFirstPlayerOrLibrary);
    if(orderBy === 'desc') {
      this.videosToShow.sort(this.showFirstPlayerOrLibrary).reverse();
    }
  }

  showFirstPlayerOrLibrary(a: HelpVideos, b: HelpVideos) {
    return a.type.localeCompare(b.type);
  }
}

