<ion-button
  [expand]="expand"
  [color]="color"
  [disabled]="disabled || loading"
  (click)="clickEvent.emit($event)"
  [fill]="fill">
  <ion-icon *ngIf="iconPath" [src]="iconPath" [style.margin-right]="label ? '1rem' : 'unset'"></ion-icon>
  {{buttonText}}
  <ion-spinner *ngIf="loading"></ion-spinner>
</ion-button>
