<div class="modal-action-confirmation-wrapper">
  <div class="modal-action-confirmation-header">
    <app-text [type]="'heading'" [label]="title" [weight]="'medium'" [size]="'md-text'"></app-text>
  </div>
  <div class="modal-action-confirmation-body" [innerHtml]="description | safeHtml" *ngIf="html"></div>
  <div class="modal-action-confirmation-body" *ngIf="!html">
    <app-text [label]="description" [weight]="'regular'" [size]="'xs'"></app-text>
  </div>
  <div class="modal-action-confirmation-footer">
    <app-button [label]="'Cancel' | translate" (click)="cancelClick()" [expand]="'full'" [color]="'secondary'"></app-button>
    <app-button [label]="'Confirm' | translate" (click)="confirmClick()" [expand]="'full'"></app-button>
  </div>
</div>
