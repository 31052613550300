import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, MatProgressSpinnerModule]
})
export class LoadingComponent {
  @Input() whiteSpinner = false;
  @Input() diameter = 23;
  @Input() loading = true;
}
