export enum PLAYER_SPACING {
  DEFAULT_PADDING_LEFT = 24, // px
  FULLSCREEN_PADDING_LEFT = 0, // px
  DEFAULT_PADDING_BOTTOM = -24, // px

  APP_DEFAULT_PADDING_BOTTOM = -20, // px

  REVIEW_FULLSCREEN_WIDTH = 97, // rem
  REVIEW_DEFAULT_WIDTH = 70, // rem

  APP_REVIEW_FULLSCREEN_WIDTH = 100, // rem
  APP_REVIEW_DEFAULT_WIDTH = 100, // rem

  REVIEW_DEFAULT_HEIGHT = 50, // vh
  REVIEW_FULLSCREEN_HEIGHT = 80, // vh

  INFO_REVIEW_HEIGHT = 85, // vh
  APP_DO_MARKUP_CONTAINER_HEIGHT = 7, // vh

  APP_REVIEW_DEFAULT_HEIGHT = 30, // rem
  APP_REVIEW_FULLSCREEN_HEIGHT = 89, // rem


  HEADER_HEIGHT = 2.5, // rem
  BRANDING_HEADER_HEIGHT = 2, // rem
  FOOTER_HEIGHT = 2, // rem

  TOOL_BAR_MARGIN_TOP = .5, //rem
  TOOL_BAR_HEIGHT = 4.5, //rem

  CONTROL_BAR_HEIGHT = 3,//rem

}
