export interface LIVE_REVIEW_EVENT {
  action: LIVE_REVIEW_ACTION;
  roomId: string;
  caller: string;
  data: any;
  connectionIds: string[]
}

export enum LIVE_REVIEW_ACTION {
  PLAY = "PLAY",
  PAUSE = "PAUSE",
  SEEK = "SEEK",
  LOOP = "LOOP",
  MARKUP = "MARKUP",
  ASK_STATE = "ASK_STATE",
  STATE = "STATE",
  CLEAR_MARKUP = "CLEAR_MARKUP",
}
