import { InputTextAreaComponent } from './../input-textarea/input-textarea.component';
import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { InputTextComponent } from '../input-text/input-text.component';
import { AbstractControl, FormControl } from '@angular/forms';
import { InputCheckboxComponent } from '../input-checkbox/input-checkbox.component';
import { TextSize, TextWeight } from 'src/app/models/types/text-types';
import { InputType } from 'src/app/models/types/input-types';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [CommonModule, IonicModule, InputTextComponent, InputCheckboxComponent, InputTextAreaComponent],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent {
  @Input() type: `checkbox` | 'date' | `phone` | 'textarea' | 'select' | 'text' = 'text';
  @Input() textType: InputType  = 'text';
  @Input() enterKeyHint: 'done' | 'enter' | 'go' | 'next' | 'previous' | 'search' | 'send' | undefined;
  @Input() inputMode: `decimal` | `email` | `none` | `numeric` | `search` | `tel` | `text` | `url` | undefined;
  @Input() control: FormControl;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() selectHeader: string;
  @Input() loading: boolean;
  @Input() icon: boolean = true;
  @Input() labelColor: string = '#464E5F';
  @Input() labelSize: TextSize;
  @Input() labelWeight: TextWeight = 'regular';
  @Output() selectedChange = new EventEmitter<string>();
  @Output() blurEventTextArea = new EventEmitter<string>();
  @Output() onFocus = new EventEmitter<boolean>();
  @Output() onBlur = new EventEmitter<boolean>();


  get isRequired() {
    if (typeof this.control.validator !== `function`) {
      return false;
    }
    const validator = this.control.validator({} as AbstractControl);
    return validator && validator['required'];
  }

  onEnterPressed(event: KeyboardEvent) {
    if (this.control.valid) {
      // this.keyboardEnterPressEvent.next(event);
    } else {
      this.control.markAsTouched();
    }
  }
}
