import { Injectable } from '@angular/core';
import { ActionSheetButton, ActionSheetController, ModalController } from '@ionic/angular';
import { UserIdentifierComponent } from '../components/modal/user-identifier/user-identifier.component';
import { ShareMediaWithUserAvatar } from '../models/media-with-user-avatar';
import { ViewVersionsComponent } from '../components/modal/view-versions/view-versions.component';
import { CreateLiveReviewRoomComponent } from '../components/modal/create-live-review-room/create-live-review-room.component';
import { HelpVideosModalComponent } from '../components/modal/help-videos-modal/help-videos-modal.component';
import { ActionConfirmationComponent } from '../components/modal/action-confirmation/action-confirmation.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private modalController: ModalController, private actionSheetController: ActionSheetController) { }

  dismissModal(data?: any, role?: string) {
    this.modalController.dismiss(data, role);
  }

  async userIdentifier() {
    const customModal = await this.modalController.create({
      component: UserIdentifierComponent,
      backdropDismiss: false,
      cssClass: 'full-modal'
    });

    customModal.present();
    return customModal;
  }

  async openSelectQualityDialog(buttons: (string | ActionSheetButton<any>)[]) {
    const actionSheet = await this.actionSheetController.create({
      cssClass: 'wk-action-sheet',
      buttons: buttons,
    });

    actionSheet.present();

    return actionSheet;
  }

  async openVersionDialog(media: ShareMediaWithUserAvatar) {
    const customModal = await this.modalController.create({
      component: ViewVersionsComponent,
      backdropDismiss: false,
      cssClass: 'view-versions-modal',
      componentProps: {
        mediaId: media.id,
      }
    });

    customModal.present();
    return customModal
  }

  async openCreateLiveReviewRoomDialog() {
    const customModal = await this.modalController.create({
      component: CreateLiveReviewRoomComponent,
      backdropDismiss: false,
      cssClass: 'create-live-review-room',
    });

    customModal.present();
    return customModal
  }

  async openConfirmationDialog(title: string, description?: any, html: boolean = false) {
    const customModal = await this.modalController.create({
      component: ActionConfirmationComponent,
      cssClass: 'confirmation-dialog-modal',
      componentProps: {
        title,
        description,
        html
      }
    });

    customModal.present();

    return customModal
  }

  async openHelpVideos(isMobile: boolean, hasBranding: boolean) {
    const customModal = await this.modalController.create({
      component: HelpVideosModalComponent,
      backdropDismiss: false,
      cssClass: 'full-modal',
      componentProps: {
        isMobile,
        hasBranding
      }
    });

    customModal.present();

    return customModal;
  }



}
