import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'app-form-error',
  standalone: true,
  imports: [CommonModule, IonicModule, TextComponent],
  templateUrl: './form-error-component.html',
  styleUrls: ['./form-error-component.scss']
})
export class FormErrorComponent {
  @Input() control: AbstractControl;
  @Input() errorMessage: string;
  @Input() patternMsg: string;
}
