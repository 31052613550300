import { ChangeDetectorRef, Component, Injector } from "@angular/core";
import { WolkieDialogComponent } from "./wolkie-dialog.component";
import { DialogService } from "./services/dialog.service";
import { UntypedFormBuilder } from "@angular/forms";

@Component({
  template: ``
})
export abstract class WolkieComponent extends WolkieDialogComponent {
  dialogService: DialogService;
  cd: ChangeDetectorRef;
  fb: UntypedFormBuilder;
  constructor(injector: Injector) {
    super(injector);

    this.cd = injector.get<ChangeDetectorRef>(ChangeDetectorRef);
    this.dialogService = injector.get<DialogService>(DialogService);
    this.fb = injector.get<UntypedFormBuilder>(UntypedFormBuilder);
  }
}
