export interface SocketEvent {
  action: SOCKET_EVENT_ACTION;
  data: any;
}

export enum SOCKET_EVENT_ACTION {
  OPEN = 'open',
  ERROR = 'error',
  CLOSE = 'close',
  PONG = 'pong',
  ADD_UPDATE_MARKUP = `ADD_UPDATE_MARKUP`,
  DELETE_MARKUP = `DELETE_MARKUP`,
  UPDATE_REVIEW_ROOMS = 'UPDATE_REVIEW_ROOMS',
  UPDATE_ROOM_MEMBERS = 'UPDATE_ROOM_MEMBERS',
  LIVE_REVIEW = 'LIVE_REVIEW',
}
