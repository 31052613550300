import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Share } from '../models/share';
import { ShareMediaWithUserAvatar } from '../models/media-with-user-avatar';
import { ReviewApiService } from './review-api.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { WolkieService } from './wolkie.service';
import { ARCHIVE_STATUS } from '../models/enums/archive-status';
import { WorkspaceBrandingWithLogoAndBranding } from '../models/responses/workspace-branding-logo';
import { DialogService } from './dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ShareLibraryService {
  medias$ = new BehaviorSubject<ShareMediaWithUserAvatar[]>([]);
  mediasToShow$ = new BehaviorSubject<ShareMediaWithUserAvatar[]>([]);
  isTableView: boolean = true;
  selectedMedias$ = new BehaviorSubject<ShareMediaWithUserAvatar[]>([]);
  share: Share;
  isLoading = false;
  workspaceBranding: WorkspaceBrandingWithLogoAndBranding;
  sortOrderSelected: 'asc' | 'desc' = 'asc';
  sortOptionSelected: "createdAt" | "name" | "status" | "type" = 'createdAt';
  menuTrigger: MatMenuTrigger;
  mobileMode$ = new BehaviorSubject<boolean>(false);
  facebookUrl: string;
  youtubeUrl: string;
  instagramUrl: string;
  webSiteUrl: string;

  get currentFolderId(): string {
    const urlSplitted = this.router.url.split("/");
    const lastSection = urlSplitted[urlSplitted.length - 1];
    return lastSection.split(`?`)[0];
  };

  constructor(
    private reviewApiService: ReviewApiService,
    private authService: AuthService,
    private router: Router,
    private wolkieService: WolkieService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private title: Title
  ) { }

  openMenu(menuTrigger: MatMenuTrigger) {
    this.menuTrigger = menuTrigger;
    this.menuTrigger.openMenu();
  }

  closeMenus() {
    this.menuTrigger?.closeMenu();
  }

  async getShare() {
    this.share = (await this.reviewApiService.getShare()).data;
  }

  setShare(statusOption: string, anonymousName: string) {
    this.share.anonymousName = anonymousName;
    this.share.status = statusOption;
  }

  navigateToReview(mediaId: string, forceReturnUrl?: string) {
    const queryParams = { returnUrl: forceReturnUrl || this.router.url };
    this.router.navigate([`review/${mediaId}`], { queryParams });
  }

  navigateToReviewVersion(mediaId: string, versionId: string, forceReturnUrl?: string) {
    const queryParams = { returnUrl: forceReturnUrl || this.router.url };
    this.router.navigate([`review/${mediaId}/${versionId}`], { queryParams });
  }

  async getShareAllMedias() {
    this.medias$.next([]);
    this.medias$.next((await this.reviewApiService.getShareMedias()).data);
  }

  async getMedias() {
    this.isLoading = true;
    this.medias$.next([]);
    this.medias$.next((await this.reviewApiService.getShareMedias()).data);
    this.setMediasToShow();
    this.isLoading = false;
  }

  setMediasToShow() {
    this.mediasToShow$.next([]);
    const token = this.authService.decodedToken$.getValue();
    console.log('[currentFolder]', this.currentFolderId);
    if (token?.shareId === this.currentFolderId) {
      if(this.share && this.share.allowShowOnlyLastVersion) {
        this.mediasToShow$.next(this.medias$.getValue().filter(m => {
          if (m.versionOfId) {
            return m.shareReferenceId === m.versionOfId;
          } else {
            return m.shareReferenceId === m.id;
          }
        }));
      } else {
        this.mediasToShow$.next(this.medias$.getValue().filter(m => m.shareReferenceId === m.id && !m.versionOfId));
      }
    } else {
      if(this.share && this.share.allowShowOnlyLastVersion) {
        this.mediasToShow$.next(this.medias$.getValue().filter(m => {
          if (m.versionOfId) {
            return this.currentFolderId === m.shareReferenceParentId;
          } else {
            return m.parentId === this.currentFolderId;
          }
        }));
      } else {
        this.mediasToShow$.next(this.medias$.getValue().filter(m => m.parentId === this.currentFolderId && !m.versionOfId))
      }
    }
  }

  setOnlyFilesToShow() {
    this.mediasToShow$.next(this.medias$.getValue().filter(m => !m.versionOfId && m.type !== 'folder' && m.type !== 'project'));
  }

  async setBrand() {
    this.workspaceBranding = (await this.reviewApiService.getBrand()).data;
    if(this.workspaceBranding.branding) {
      this.title.setTitle(this.workspaceBranding.pageTitle || 'Wolkie Review');
      const favIcon: HTMLLinkElement | null = document.querySelector('#favIcon');
      if(!favIcon) { return }
      favIcon.href = this.workspaceBranding.pageIconUrl || 'assets/wolkie-symbol.ico';
    }
  }

  setOrderMedias(order: "asc" | "desc") {
    this.sortOrderSelected = order;
    this.filterMedias();
  }

  setSortOptions(orderOption: string) {
    this.sortOptionSelected = orderOption as "createdAt" | "name" | "status" | "type";
    this.filterMedias();
  }

  filterMedias() {
    switch (this.sortOptionSelected) {
      case 'name':
        this.mediasToShow$.next(this.mediasToShow$.getValue().sort((a, b) => {
          if (this.sortOrderSelected === 'asc') {
            return a.name.localeCompare(b.name);
          }
          return b.name.localeCompare(a.name);
        }));
        break;
      case 'status':
        this.mediasToShow$.next(this.mediasToShow$.getValue().sort((a, b) => {
          if (this.sortOrderSelected === 'asc') {
            return a.status.localeCompare(b.status);
          }
          return b.status.localeCompare(a.status);
        }));
        break;
      case 'type':
        this.mediasToShow$.next(this.mediasToShow$.getValue().sort((a, b) => {
          const stringAToCompare = a.type + a.mimeType;
          const stringBToCompare = b.type + b.mimeType;
          if (this.sortOrderSelected === 'asc') {
            return stringAToCompare.localeCompare(stringBToCompare);
          }
          return stringBToCompare.localeCompare(stringAToCompare);
        }));
        break;
      case 'createdAt':
        this.mediasToShow$.next(this.mediasToShow$.getValue().sort((a, b) => {
          const dateA = new Date(a.createdAt).getTime(); // Convert "date" string to Date object
          const dateB = new Date(b.createdAt).getTime();
          return this.sortOrderSelected === 'asc' ? dateA - dateB : dateB - dateA;
        }));
        break;
      default:
        break;
    }
  }

  toggleDisplayMode() {
    this.isTableView = !this.isTableView;
  }

  addOrUpdateMedia(mediaToUpdate: any, addIfNotExists = true, placeFirst = false) {
    const index = this.mediasToShow$.getValue().findIndex(m => m.id === mediaToUpdate.id);
    const updatedMedias = this.mediasToShow$.getValue();
    if (index >= 0) {
      updatedMedias[index] = mediaToUpdate
    } else if (addIfNotExists) {
      if (placeFirst) {
        updatedMedias.unshift(mediaToUpdate);
      } else {
        updatedMedias.push(mediaToUpdate);
      }
    }
    this.mediasToShow$.next(updatedMedias);
  }

  updateMediasStatus( status: string) {
    const updatedMedias = this.medias$.getValue();

    this.medias$.next(updatedMedias.map(media => ({
      ...media,
      status: status,
    })));
    this.setMediasToShow();
  }

  async download(media: ShareMediaWithUserAvatar, qualities: string[]) {
    console.log(qualities);
    switch(media!.type){
      case 'file':
        await this.downloadMediaToBrowserV2(media!, qualities);
        break;
        case 'file_version':
        await this.downloadVersions(media!, qualities);
        break;
      default:
        const medias = this.medias$.getValue().filter(x => {
          if (x.versionOfId) {
            return x.shareReferenceParentId === media.versionOfId
          } else {
            return x.parentId === media.id;
          }
        });
        medias.forEach(async m => {
          await this.download(m, qualities);
        });
        break;
    }
  }

  private async downloadVersions(media: ShareMediaWithUserAvatar, qualities: string[]) {
    const versions = (await this.reviewApiService.getVersions(media.id)).data;
    const lastVersion = versions[versions.length - 1];
    this.download(
      lastVersion,
      qualities
    )
  }

  private async downloadMediaToBrowserV2(media: ShareMediaWithUserAvatar, qualities: string[]): Promise<void> {
    const urls = (await this.reviewApiService.getMediaFiles(media.id))!.data;
    if (media.archiveStatus === ARCHIVE_STATUS.RESTORING) {
      this.errorFn('', media.archiveStatus, media.name);
    }
    console.log('[qualities]', qualities);
    if(media.mediaType === 'video' || media.mediaType === 'audio') {
      qualities = qualities.map(str => str === 'proxy' ? str = '360p' : str);
    }
    qualities.forEach(quality => {
      const url = urls.find(u => u.quality.toLowerCase() === quality.toLowerCase())?.url;
      if (!url) {
        console.log(`URL NOT FOUND FOR SPECIFIED QUALITY ${quality}`);
        return;
      }

      this.downloadUrlToBrowser(url);
    });
  }

  private downloadUrlToBrowser(url: string) {
    const w = window.open(url, `_blank`);
    // if (!w) {
    //   this.toastrService.warning(this.translateService.instant('general.allowPopup'), null, {timeOut: 7000});
    // }
  }

  private errorFn (url: string, archivedStatus: ARCHIVE_STATUS, name?: string) {
    let filename = '';
    if (name) {
      filename = name;
    } else {
      const urlClass = new URL(url);
      const searchParams = new URLSearchParams(urlClass.search);
      filename = searchParams.get(`response-content-disposition`)?.split(`filename=`)[1] || '';
    }

    // this.toast.info(this.translateService.instant(archivedStatus === ARCHIVE_STATUS.ARCHIVED ? 'fileNameIsArchived' : 'fileNameIsRestoring', {fileName: filename}));
  }

  navigateToCompareVersion(mediaId: string) {
    this.router.navigate([`/compare-version/${mediaId}`])
  }

  isShareValid() {
    if (this.isShareInactive()) {
      this.wolkieService.toast.error(this.translateService.instant('ShareIsNotActive'), '');
      return false;
    }

    if (this.isShareExpired()) {
      this.wolkieService.toast.error(this.translateService.instant('ShareIsExpired'), '');
      return false;
    }

    return true;
  }

  isShareInactive() {
    return !this.share.active;
  }

  isShareExpired() {
    return !!this.share.expireDate && new Date().getTime() > new Date(this.share.expireDate).getTime();
  }

  getBrandingUrls() {
    if(this.workspaceBranding.branding) {
      this.facebookUrl = this.workspaceBranding.facebookUrl ? this.workspaceBranding.facebookUrl : '';
      this.youtubeUrl = this.workspaceBranding.youtubeUrl ? this.workspaceBranding.youtubeUrl : '';
      this.instagramUrl = this.workspaceBranding.instagramUrl ? this.workspaceBranding.instagramUrl : '';
      this.webSiteUrl = this.workspaceBranding.webSiteUrl ? this.workspaceBranding.webSiteUrl : '';
    } else {
      this.facebookUrl = this.workspaceBranding.facebookUrl ? this.workspaceBranding.facebookUrl : 'wolkie.io';
      this.youtubeUrl = this.workspaceBranding.youtubeUrl ? this.workspaceBranding.youtubeUrl : '@wolkieio';
      this.instagramUrl = this.workspaceBranding.instagramUrl ? this.workspaceBranding.instagramUrl : 'wolkie.io';
      this.webSiteUrl = this.workspaceBranding.webSiteUrl ? this.workspaceBranding.webSiteUrl : 'wolkie.io';
    }
  }

  async requestConfirmationWhenApproveShare() {
    const confirmationModal = await this.dialogService.openConfirmationDialog('confirmShareApproveTitle',
      `<span style="font-family: Poppins;">
        ${this.translateService.instant('confirmShareApproveDescription.begin')} <span style="font-weight: bold">${this.translateService.instant('confirmShareApproveDescription.approved')}</span><br />
       <span>${this.translateService.instant('confirmShareApproveDescription.end')}</span>
      </span>`, true);


    return confirmationModal;
  }
}
