import { Injectable } from '@angular/core';
import { UserToken } from '../models/token';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DialogService } from './dialog.service';
import { BehaviorSubject } from 'rxjs';
import { ReviewApiService } from './review-api.service';
import { ViewUserWithWorkspaces } from '../models/views/view-user-with-workspaces';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  decodedToken$: BehaviorSubject<UserToken | null>;
  private jwtHelper: JwtHelperService;

  authenticatedUser$ = new BehaviorSubject<ViewUserWithWorkspaces | null>(null);

  set token(token: string | null) {
    if (token) {
      localStorage.setItem(`user_token`, token);
    } else {
      this.clearUserToken();
    }
    this.decodedToken$.next(this.decodedToken);
  }

  get token(): string | null{
    return localStorage.getItem(`user_token`);
  }

  private get decodedToken(): UserToken | null {
    const token = this.token;
    if (!token) {
      return null;
    }

    if (this.jwtHelper.isTokenExpired(token)) {
      this.clearUserToken();
      return null;
    }

    return this.jwtHelper.decodeToken(token) as UserToken;
  }

  constructor(
    private dialogService: DialogService
  ) {
    this.jwtHelper = new JwtHelperService();
    this.decodedToken$ = new BehaviorSubject<UserToken | null>(this.decodedToken);
  }

  async forceUserToIdentify() {
    return new Promise(async resolve => {
      const token = this.decodedToken;
      console.log('[token]', token);

      if (!token?.identified) {
        const modal = await this.dialogService.userIdentifier();
        await modal.onDidDismiss();

        if (this.decodedToken?.identified) {
          resolve(true);
        } else {
          await this.forceUserToIdentify();
          resolve(true);
        }
      } else {
        resolve(true);
      }
    });
  }

  clearUserToken() {
    if (this.token) {
      localStorage.removeItem(`user_token`);
    }
  }
}
