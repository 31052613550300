import { CommonModule } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { WK_COLORS } from 'src/app/models/enums/wk-colors';
import { GenerateReviewToken } from 'src/app/models/generate-review-token';
import { WolkieComponent } from 'src/app/wolkie.component';
import { AvatarComponent } from '../../shared/avatar/avatar.component';
import { ButtonComponent } from '../../shared/button/button.component';
import { InputComponent } from '../../shared/input/input.component';
import { TextComponent } from '../../shared/text/text.component';

@Component({
  selector: 'app-user-identifier',
  standalone: true,
  imports: [CommonModule, IonicModule, InputComponent, TextComponent, ButtonComponent, AvatarComponent, TranslateModule],
  templateUrl: './user-identifier.component.html',
  styleUrls: ['./user-identifier.component.scss']
})
export class UserIdentifierComponent extends WolkieComponent implements OnInit {
  avatarColors = WK_COLORS.anonymousAvatar;
  avatarColorSelected = this.avatarColors[0];
  passwordIsRequired = false;
  loading = false;
  form = this.fb.group({
    name: [null, Validators.required],
    email: [null, [Validators.required, Validators.email]],
    password: [null],
    termsAgreementAndPolice: [null, [Validators.required]]
  });

  get name(): FormControl {
    return this.form.get('name') as FormControl;
  }

  get email(): FormControl {
    return this.form.get('email') as FormControl;
  }

  get reviewCode(): FormControl {
    return this.form.get(`reviewCode`) as FormControl;
  }

  get password(): FormControl {
    return this.form.get(`password`) as FormControl;
  }

  get termsAgreementAndPolice(): FormControl {
    return this.form.get(`termsAgreementAndPolice`) as FormControl;
  }

  constructor(
    injector: Injector
  ) {
    super(injector);

    this.termsAgreementAndPolice.valueChanges
      .pipe(this.takeUntilDestroy())
      .subscribe(value => {
        if (value === false) {
          this.termsAgreementAndPolice.setValue(null);
        }
      });
  }

  ngOnInit() {
    const tokenValue = this.authService.decodedToken$.getValue();
    if (tokenValue && tokenValue.passwordRequired) {
      this.passwordIsRequired = true;
      this.password.setValidators([Validators.required, Validators.minLength(3)]);
    }
  }

  async submitForm() {
    try {
      this.loading = true;
      if (this.form.valid) {
        const formValue = this.form.value;
        await this.identifyUser(formValue.name, formValue.email, formValue.password, this.avatarColorSelected);
      } else {
        this.formHelper.markFormGroupTouched(this.form);
      }
    } catch (error) {} finally {
      this.loading = false;
    }
  }

  async identifyUser(name: string, email: string, password: string, avatarColor: string) {
    const model: GenerateReviewToken = { name, email, password, avatarColor };
    const token = (await this.reviewApiService.identifyUser(model)).data.token;
    this.authService.token = token;
    await this.modalController.dismiss();
  }

  onFocus() {
    this.reviewService.inputFocus = true;
  }
  onBlur() {
    this.reviewService.inputFocus = false;
  }

  selectColor(color: string) {
    this.avatarColorSelected = color;
  }

  getAvatarText(): string {
    let name = this.name.value;
    if (!name) {
      name = 'Wolkie Space';
    }
    let value = (name).match(/(\b\w)?/g)?.join('').match(/(^\w|\w$)?/g)?.join('') ?? '';
    if (name.split(' ').length === 1) {
      value = name.slice(0, 1);
    }
    return value;
  }
}
