<div class="input-wrapper">
  <div style="position: relative">
    <app-text [label]="label" [weight]="labelWeight" [size]="labelSize" [color]="labelColor"></app-text>
    <ion-input #input
      [formControl]="control"
      [type]="type"
      [enterkeyhint]="enterKeyHint"
      [class.has-error]="hasError"
      [placeholder]="placeholder | translate"
      [style.margin-top]="label ? '.5rem' : '0px' "
      [class.no-icon]="!icon"
      [inputmode]="inputMode"
      (ionBlur)="onBlur($event)"
      (ionChange)="onChange($event)"
      (ionFocus)="onFocus($event)"
      (ionInput)="onInput($event)"
      (keypress)="onKeyPress($event)">
    </ion-input>
    <ion-icon *ngIf="icon" class="ion-icon" [src]="'/assets/svg/input/' + type + '-icon.svg'" slot="start"></ion-icon>
  </div>
  <app-form-error [control]="control"></app-form-error>
</div>
