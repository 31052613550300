import { Routes } from '@angular/router';
import { userIdentified } from './guards/user-identified.guard';

export const routes: Routes = [
  {
    path: 'share',
    loadChildren: () => import(`./pages/share/share.routes`).then(routes => routes.ShareRoutes)
  },
  {
    path: 'share-selector',
    loadComponent: () => import('./pages/share-selector/share-selector.page').then((m) => m.ShareSelectorPage),
  },
  {
    path: 'review/:mediaId',
    loadComponent: () => import('./pages/review/review.page').then((m) => m.ReviewPage)
  },
  {
    path: 'review/:versionId/:mediaId',
    loadComponent: () => import('./pages/review/review.page').then((m) => m.ReviewPage),
  },
  {
    path: 'compare-version/:mediaId',
    loadComponent: () => import('./pages/compare-version/compare-version.page').then((m) => m.CompareVersionPage),
  },
  {
    path: '',
    redirectTo: 'share-selector',
    pathMatch: 'full',
  }
];
